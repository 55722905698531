import React, { useEffect } from 'react';
import * as guac from "guacamole-common-js";
import Button from '@mui/material/Button';

// CTRL - ALT - DEL keycodes
const ctrl = 0xFFE3;
const alt = 0xFFE9;
const del = 0xFFFF;

const guacamoleUrl = 'wss://guacamole.prod.service.hyperqube.io/';
const guacClient = new guac.Client(new guac.WebSocketTunnel(guacamoleUrl));
guacClient.onerror = () => { console.log("GUAC ERROR") };
const handleCtrlAltDelete = () => {
  console.log("Ctrl Alt Del clicked");

  guacClient.sendKeyEvent(1, ctrl);
  guacClient.sendKeyEvent(1, alt);
  guacClient.sendKeyEvent(1, del);

  const release = () => {
    guacClient.sendKeyEvent(0, ctrl);
    guacClient.sendKeyEvent(0, alt);
    guacClient.sendKeyEvent(0, del);
  };

  setTimeout(release, 500);
};

export default function Console({ token }) {

  useEffect(() => {

    const element = guacClient.getDisplay().getElement();
    element.id = "guac-console";
    document.getElementById("vnc-console").appendChild(element);

    const mouse = new guac.Mouse(element);
    mouse.onmousedown = mouse.onmousemove = mouse.onmouseup = guacClient.sendMouseState.bind(guacClient);

    const keyboard = new guac.Keyboard(document);
    keyboard.onkeydown = sym => { guacClient.sendKeyEvent(1, sym); };
    keyboard.onkeyup = sym => { guacClient.sendKeyEvent(0, sym); };

    const params = new URLSearchParams({ token: token, width: "1024", height: "768", }).toString();
    guacClient.connect(params);
  }, []);

  const consoleStyle = {
    width: '100%',
    height: '100%',
  };

  const menuStyle = {
    width: '100%',
    marginTop: '8px',
    marginBottom: '8px',
  }

  return (
    <div>
      <div style={menuStyle}>
        <Button variant="contained" onClick={handleCtrlAltDelete}>Send Ctrl-Alt-Del</Button>
      </div>
      <div id="vnc-console" style={consoleStyle}></div>

    </div>
  );
}
