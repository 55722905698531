import React from 'react';
import { useState, useEffect } from 'react';
import EricomDemoLoader from './EricomDemoLoader';
import SiteHeader from './SiteHeader';
import DemoContent from './DemoContent';

export default function Authenticated({ getToken }) {
  const [token, setToken] = useState(null);
  const demoName = window.localStorage.getItem('path');

  const runMe = async () => {
    const newToken = await getToken();
    setToken(newToken);
  };

  useEffect(() => {
    runMe();
  }, []);

  if (token == null) return null;

  if (demoName == "/ericom") {
    return (
      <div>
        <SiteHeader></SiteHeader>
        <div style={{ display: 'flex' }}>
          <EricomDemoLoader jwt={token.__raw} />
          <DemoContent />
        </div>
      </div>
    )
  }

  return (
    <div>Sorry, but there is no demo named {demoName}</div>
  )

}
