import React, {useState} from 'react'

export default function Timer({ endTime }) {
  const [countdown, setCountdown] = useState(null);
  var timer = null;

  const updateTimer = () => {
    const now = new Date();
    const nowInSeconds = parseInt(now.getTime() / 1000);
    const timeLeftSeconds = endTime - nowInSeconds;
    if (timeLeftSeconds < 1) {
      clearInterval(timer);
      setCountdown(0);
      return;
    }

    setCountdown(timeLeftSeconds);
  };  


  if (timer == null) {
    timer = setInterval(() => { updateTimer(); }, 1000);
  }

  if (countdown == null){
    return (
      <div>Time Left: 0m: 0s</div>
    )
  }

  return (
    <div>Time Left: {parseInt(countdown/60)}m: {countdown%60}s</div>
  )
}
