import React from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import Authenticated from "./components/Authenticated";
import { flexbox } from '@mui/system';


function App() {
  const { user, isAuthenticated, isLoading, getIdTokenClaims, loginWithRedirect } = useAuth0();

  const pageStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

  }

  const appStyle = {
    width: '1440px',
    height: '900px',
    boxSizing: 'border-box',
  }

  if (isLoading) return <div>Loading ...</div>;

  if (isAuthenticated) {
    return (
      <div style={pageStyle}>
        <div style={appStyle}>
          <Authenticated getToken={getIdTokenClaims} />
        </div>
      </div>
    )
  }

  loginWithRedirect();

  return null;
}

export default App;
