const URL = "https://demo-api.herokuapp.com/get-demo-session";
//const URL = "http://0.0.0.0:5000/get-demo-session";

const get = async (jwt)=>{
    const HEADERS = {
      Authorization: `Bearer ${jwt}`,
    };

    const OPTIONS = {headers: HEADERS}
    const response = await fetch(URL, OPTIONS);
    const _json = await response.json();
    console.log('getDemoSession=', _json);
    return _json;
  }
  
  export { get }