import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import * as VncToken from "../models/VncToken";
import { useLocation } from 'react-router-dom';
import Console from './Console';

export default function Demo({ vncToken }) {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Console token={vncToken} />
    </div>
  )
}
