import React, { useState, useEffect } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Demo from './Demo';
import * as DemoSession from "../models/DemoSession";
import Button from '@mui/material/Button';
import Timer from './Timer';

export default function EricomDemoLoader({ jwt }) {
  const [isLoading, setIsLoading] = useState(true);
  const [vncToken, setVncToken] = useState(null);
  const [isExprired, setIsExpired] = useState(null);
  var refresh = null;

  const runMe = async () => {
    const session = await DemoSession.get(jwt);
    const event = new CustomEvent('session_loaded', {detail: session});
    console.log('dispached event=', event);
    window.dispatchEvent(event);

    session.is_expired && setIsExpired(session.is_expired);
    session.vnc_token && setVncToken(session.vnc_token);

    if (refresh != null) return;

    refresh = setInterval(async () => {
      const refreshSession = await DemoSession.get(jwt);
      setIsExpired(refreshSession.is_expired);
      setVncToken(refreshSession.vnc_token);
    }, 5000);

    setIsLoading(false);
  }

  useEffect(() => { runMe(); }, []);

  if (isLoading) {
    return (
      <div style={{ display: "inline" }}>
        <h1>Loading...</h1>
        <CircularProgress />
      </div>
    )
  }

  if (isExprired) {
    return (
      <div>
        <h1>Demo Expired</h1>
        <Button variant="contained">Contact Sales</Button>
      </div>
    )
  }

  return (
    <div>
      <Demo vncToken={vncToken} />
    </div>
  )

}
