import React from 'react'

export default function DemoContent() {
	const style = {
		width: '100%',
		borderRadius: '15px',
		boxShadow: 'rgba(0,0,0,.3) 0 8px 24px',
		marginLeft: '8px',
		marginTop: '8px',
	}

	const txtStyle = {
		marginLeft: '8px',
		marginTop: '8px',
	}

	return (
		<div id='demo-content' style={style}>
			<div style={txtStyle}>
				DemoContent
			</div>
		</div>
	)
}
