import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";

if(window.location.pathname != "/"){
  window.localStorage.setItem('path',window.location.pathname);
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <Auth0Provider
    domain="dev-qnluh-44.us.auth0.com"
    clientId="frVUXXeN9RHpeqmVEGx9CinIW0pgdKHx"
    redirectUri={window.origin}
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);