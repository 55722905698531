import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import LogoutButton from './LogoutButton'
import Timer from './Timer'

export default function SiteHeader() {
  const [session, setSession] = useState(null);
  
  const handleSessionLoaded = (e)=>{
    setSession(e.detail);
  };

  useEffect(()=>{window.addEventListener('session_loaded', handleSessionLoaded);}, []);
  const style = {
    width: '100%',
    height: '64px',
    display: 'flex',
    boxSizing: 'border-box',
    borderBottom: '1px solid black',
    alignItems: 'center',
  }

  const logoStyle = {
    flexGrow: 1,
    boxSizing: 'border-box',
  }

  const logoutStyle = {
    flexGrow: 1,
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end'
  }

  if (session != null) {
    return (
      <div style={style}>
        <div style={logoStyle}>
          <img alt="ericom logo" src="https://ericom.com/media/smallLogo2.svg" />
        </div>
        <div style={logoStyle}><Timer endTime={session.expiration_time_seconds} /></div>
        <div style={logoutStyle}><LogoutButton /></div>
      </div>
    )
  }

  return (
    <div style={style}>
      <div style={logoStyle}>
        <img alt="ericom logo" src="https://ericom.com/media/smallLogo2.svg" />
      </div>
      <div style={logoutStyle}><LogoutButton /></div>
    </div>
  )
}
